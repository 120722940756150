ul {
    list-style: none;
  }
  
  /*#searchdetail ul li::before {
    content: "\2022";
    color: deepskyblue;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }*/
  a:not([href]):not([tabindex]).pdflink{
    color: #1a0dab;
    cursor: pointer;
  }
  a:not([href]):not([tabindex]).pdflink:hover{
    text-decoration: underline;
  }
  a.pdflink,a.pdflink:hover{
    color: #006621;
  }
  