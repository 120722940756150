.bg-primary{
    background-color: #bce8f1!important;
}
.ansfont{
    font-family: arial,sans-serif;
    font-size: 16px;
    overflow-wrap: break-word;
}

.card-title{
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    
    color: #5f6368;
}