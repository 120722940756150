@import url(https://fonts.googleapis.com/css?family=Google+Sans+Display);
@import url(https://fonts.googleapis.com/css?family=Google+Sans+Display);
body {
  margin: 0;
  padding: 0;
 font-family: 'Google Sans Display', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.md-expansion-panel--expanded:not(:last-child) {
  margin-bottom: 0px!important;
}
.md-expansion-panel--expanded:not(:first-child) {
  margin-top: 0px!important;
}
html{
  background: #fff;
}
* {
    font-family: Google Sans Display,sans-serif;
}
.card,.card-body {
    font-family: Google Sans Display,sans-serif!important;
}

body,html{
 box-sizing: border-box;
 font-size: 13px;
 font-family: 'Google Sans Display', sans-serif !important;
}
.mt-50{
  margin-top: 50px;
}
.md-check {
  height: 20px!important;
}
.md-check .material-icons.md-icon{
  font-size: 16px;
}
.md-check .md-btn--icon{
  padding:0px;
  height: 20px;
  width: 25px;
  padding-top: 1px;
}
.MuiTypography-body1-44 span:last-child{
  font-size: 13px!important;
  font-family: 'Google Sans Display', sans-serif !important;
}
.MuiTypography-body1-44 svg{
  width: 20px!important;
  height:20px!important;
}
.MuiTypography-body1-44 > span{
  padding: 6px!important;
}
.md-background--primary,.md-background--primary-hover:hover{
  background-color: rgb(3,169,244);
}

.md-divider--text-field-active, .md-divider--text-field-expanded, .md-divider--text-field::after{
  background :rgb(3,169,244);
}
.md-text--theme-primary
{
  color: rgb(3,169,244);
}
.check-box{
  width:10px;
  height: 10px;
  vertical-align: middle;

}
.w-80{
  width: 80%;
}
.w-20{
  width: 20%;
}
.knowledge-color-mark {
  width: 10px;
  height: 10px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  background-color: #66afe9;
  margin-right: -10px;
  margin-left: 6px;
  margin-bottom: 10px;
  margin-top: 5px;
}
ul#suggestion{
  max-height: 60vh;
  min-height: 335px;
  overflow: auto;
}
ul#suggestion li:hover{
  cursor: pointer;
}
a.search-items{
  line-height: 40px;
}
.input-focus{
  line-height: 25px;
  padding-bottom: 3px;
  margin-top: 2px;
  border-bottom: 2px solid #ccc;
}
input.input-focus:focus{
  border-bottom: 2px solid rgb(3,169,244);
}

label.md-background--primary-hover:hover{
  background: #aaa
}

.dropdown-menu{
  min-width:13rem;
}
.unstyled li:hover{

 font-weight:bold;
}
#x{
  top:10px;
  right:25px;
  background: none;
  z-index: 11;
}
header a{
 cursor: pointer;
}
a#x:before {
 float: right !important;
 font-family: FontAwesome;
 content:'\f00d';
 padding-right: 5px;
}
a#x.collapsed:before {
 float: right !important;
 font-family: FontAwesome;
 content:"\f078";
}

.variable-value,.variable-value div{
  display: inline!important;
}

.radius-lg{
  border-radius: 15px !important;
}
header p{
  line-height: 17px;
  font-weight: bold;
  font-size: 17px;  
}
mark{
  background-color: rgb(255, 255, 0);  
 
}

.scrollpdf{
  max-height: 650px;
  max-width:700px;
  overflow: auto;
  position: relative;
}
.dropdown{
  width: 11%;
}
.input-group{
  width: 90%;
}
input[type="text"]::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #282c34;
  opacity: 0.5; /* Firefox */
}
input[type="text"]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #282c34;
  opacity: 0.5; /* Firefox */
}
.btn.disabled,.btn:disabled{
  opacity: 1;
}
em{
 
  color: blue;
  overflow-wrap: break-word;
}
.App {
  text-align: center;
}

.App-logo {
  height: 35px;
  width: 65px;
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-top: 4px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-group button {
  padding: 5px 2px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}
.response-btn {
    background-color:#f4f7f9;
    border-radius :10px;
    max-width:215px;
    text-align:left;
    min-width: 50px;
    text-align: center;
    margin-right: 5px;
}
.btn-group button:not(:last-child) {
  border-right: none; 
}

.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.tNode{
  background-color: aliceblue;
  border: 1px solid cadetblue;
  
}
.tNodeContent
{
  max-height: 70px;
    overflow: auto;
}
.__react_component_tooltip{
  z-index: 3!important;
  padding: 6px 11px!important;
}
.Resizer {
  box-sizing: border-box;
  background-color: #eee;
  opacity: 0.5;
 
  background-clip: padding-box;
  z-index: 1;
  display:none
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}


svg{
  overflow: unset;
}

.parent {
  width: 100%;
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.SplitPane{
  overflow: visible!important;
  padding-right: 10px;
  height: auto!important;
}

.Pane2{
  background-color: #fff;
  z-index: 15;
  height: 99%;
  
}
.nav-pills a{
  color: #282c34;
  font-weight: bold;
  opacity: 0.5;
}
.nav-pills a.active{
   color: #282c34;
   font-weight: bold;
   opacity: 1;
}

div::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #eee;
  
}

div::-webkit-scrollbar
{
	width: 8px;
	background-color: #eee;
  
}

div::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #bce8f1;
 
}

.toolTip{
  width: 90px!important;
  min-height: 80px!important;
  max-height: 110px!important;
  z-index: 11111111;
  top:0px!important;
  padding: 1px!important; 
}
#docRetriever em{
  color:#212529!important;
  font-style:normal!important;
}



.styled-checkbox {
  position: absolute;
  opacity: 0;
  margin-left: 5px;
    margin-top: 2px;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  width:100%;
}
.styled-checkbox + label:before {
  content: '';
  margin-right: 5px;
  margin-top:4px;
  display: inline-block;
  vertical-align: text-top;
  width: 11px;
  height: 11px;
  background: white;
  float:left;
  border:1px solid #5f6368;
}
.styled-checkbox:hover + label:before {
  background: #fff;
}

.styled-checkbox:checked + label:before {
  background: #fff;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 1px;
  top: 8px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 green, 4px 0 0 green, 4px -2px 0 green, 4px -4px 0 green, 4px -6px 0 green, 4px -8px 0 green;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.form-table tr td{
  padding: 10px;
  max-width: 300px;
    overflow-wrap: break-word;
}

.modal-dialog{
  top: 100px;
}
.dropdown-header{
  font-size:13px;
  font-weight:400;
}


.rc-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  color: #666;
}
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding:5px 8px 0px 8px;
}
.rc-menu-horizontal {
  border:none;
}
.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding:0px;
  border-bottom: 0px solid #dedede;
}
.rc-menu-item-active,.rc-menu-submenu-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: #ccc;
}

.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-vertical > .rc-menu-item{
  padding: 12px 12px 12px 15px!important;
}
.rc-menu-sub .rc-menu-sub > .rc-menu-item{
  padding: 3px 3px 1px 15px!important;
}

.rc-menu-item-selected {
  background-color: #fff;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.rc-menu-submenu-selected {
  background-color: #ccc;
}

.rc-menu-horizontal {
  background-color: #F3F5F7;
  border: none;
  border-bottom: 0px solid #d9d9d9;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
}

.rc-menu-horizontal > .rc-menu-submenu,
.rc-menu-horizontal > .rc-menu-item {
  border-bottom: 2px solid transparent;
  display: inline-block;
  vertical-align: bottom;
}
.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: 0px solid #2db7f5;
  background-color: #F3F5F7;
  color: #fff;
}
.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0;
}
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu {
  padding: 0px;
}
.rc-menu-vertical.rc-menu-sub{
  min-width: 180px;
}




.react-autosuggest__container {
  position: relative;
  width:95%;
}

.react-autosuggest__input {
  width: 100%;
  padding: 5px;
  padding-left: 20px;
  font-family: Helvetica, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.react-autosuggest__suggestion-match {
  color: #000000;
  font-weight: bold;
}

.select-kb{
  min-width: 200px!important;
}

.ReactTable .rt-th, .ReactTable .rt-td {  
   white-space: normal!important; 
   text-overflow: initial!important;
  overflow-wrap: break-word;
  
}

.rc-menu-vertical{
  max-height: 300px;
  overflow: auto;
}

.query-icon{
  font-size: 30px;
  cursor:pointer;
  color: #999;
  margin-left: 5px;
}

.nav-pills .nav-link.active{
  color: #282c34;
  background-color: unset;
}
.w-10{
width: 10%;
}
.w-20{
  width: 20%;
  }
  .w-80{
    width: 80%;
    }
.w-90{
  width: 90%;
  }
  svg.rcw-open-launcher,button svg{
    height: auto!important;
  }
 div svg{
  width: unset!important;
   height: unset!important;
 }
 #graph svg{
   height: 100vh!important;
 }

 table#knowledgebase-map div svg{
  width: 25px!important;
  }

 div.rd3t-tree-container svg{
  width: 100%!important;
  height: 100%!important;
 }

 .dropdown-item:active{
   background-color: unset;
   color: #000000;
 }
 .dropdown-item:focus{
   outline: none;
 }
iframe{
  width: 100%;
  height:100vh;
}
p.content-overlap,li.content-overlap{
  overflow-wrap: break-word;
}
.filter-scroll{
  max-height: 100vh;
  overflow: auto;
}
.md-check label{
  margin-top: 3px;
  margin-bottom: 3px;
  max-width:160px;
}
.md-check label span
{
  text-align: left;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn{
  font-size: 13px!important;
  font-family: 'Google Sans Display', sans-serif !important;
}
.h-10{
 height:10px; 
}
.bg-blue{
  background-color: #d7f2fa;
  border-radius: 7px!important;
  height:auto!important;  
}
.shadow-none
{
  box-shadow: none!important;
}
.content-scroll{ 
 padding: 1px;
 max-height: 200px;
  overflow-y: auto;
}
.md-panel-header{
  font-size: 13px!important;
}
.fa-search:before{
  font-size: 13px!important;
}
img.kaas-logo{
 width:75px; 
}
.small-text{
  font-size: 11px;
  font-weight: normal;
  font-family: 'Google Sans Display', sans-serif !important;

}

.MuiInput-underline:after,.MuiInput-underline:before{
  border-bottom: unset!important;
}

.MuiInputBase-input{
  border: 1px solid #ced4da!important;
  border-radius: 5px; 
  padding-left: 5px!important;
}

p[contenteditable="true"]{
  border:1px solid #eeeeee;
}

#start_img{
  width: 31px!important;
  height: 31px!important;
}

.App{
  width: unset!important;
  height: unset!important;
  margin-left: unset!important;  
}

.App .right{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: .25rem!important;
 border: 1px solid #ced4da!important;
}

.App #results{
  display: none!important;
}

#editKbScroll{
  max-height: 500px;
  overflow: auto;
}
#previewEditkbScroll{
  /*max-height: 80vh;
  overflow: auto;*/
}

#react-autowhatever-1 {
    z-index: 999;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  /*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/P�4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);*/
  border-right: 3px solid grey;
    border-bottom: 3px solid grey;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.box{
  display: inline-block;
    background: #fff;
    border: 1px solid #cccccc;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
   
   

}

.box iframe{
 width:100%!important;
 height: 100%!important;
}

button.md-background--primary:disabled, button.md-background--primary-hover:hover:disabled {
  background-color: #cccccc;
}
.docEditSubmit{
  position: absolute;
   
    bottom: 20px;
    margin-left: 270px;
  
}
div.MuiInputBase-root svg{
width: 1em!important;
    height: 1em!important;
}

img.imagePreview{
  width: 100%;
    height: auto;
    object-fit: contain;
}

.MuiAutocomplete-listbox{
  max-width:100%!important; 
  overflow-x:hidden!important; 
  white-space:nowrap!important; 
  text-overflow:ellipsis!important;
}
.MuiAutocomplete-option{
  max-width:95%!important; 
  overflow-x:hidden!important; 
  white-space:nowrap!important; 
  text-overflow:ellipsis!important;
}
.MuiAutocomplete-option::after{
  content:''
}
#combo-box-demo-label{
  display: none;
}
.MuiFormControl-marginNone{
  margin: 0px;
}

label + .MuiInput-formControl{
  margin-top: 0px!important;
}

.MuiInputBase-input#combo-box-demo{
  border: none!important;
}
.MuiAutocomplete-inputRoot{
  border: 1px solid #cccccc;
}


/*settings*/
.md-slider-thumb--on,.md-slider-track-fill,.md-slider-thumb--discrete-active-off,.md-slider-thumb--discrete-off{
  background:rgb(3,169,244);
}
.md-slider-thumb--discrete-on::after,.md-slider-thumb--discrete-active-off::after{
  border-top-color: rgb(3,169,244);
}
.dual-list-groupa,.dual-list-groupb{
  
}
.dual-list-groupb{
  margin-left: 20px;
}
.e-listbox-wrapper{
  overflow: unset!important;
}

.e-listbox-wrapper .e-list-parent {
height: 243px;
width:151px;
}
.e-list-item{
padding-top: 10px;
padding-bottom: 10px;
margin-left: 10px
}
.e-listboxtool-wrapper .e-listbox-tool{
  overflow: unset!important;
}

.e-listboxtool-wrapper.e-right .e-listbox-tool{
  margin-right: 25px;
}
.e-listbox-wrapper .e-listbox-template{
  margin-left:20px;
}




/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */   
    width: 100%;   
   background-color: #f9f9f9;
    color: #000000;
    text-align: center;
    border-radius: 2px;
    padding: 2px 2px 2px 10px;
    position: fixed;
    z-index: 1;
    left: 0%;
    bottom: 0px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}



.progress{
	height:7px!important;
}

.progress-bar{
	background-color:#35cce6!important
}

.w-85{
	width:85%;
}
.w-15{
	width:15%;
}
.w-60{
  width:60%;
}
.w-40{
  width:40%;
}





.tablink {
 
  color: black;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
  font-size: 13px;
  width: 10%;
}

/* Style the tab content */
.tabcontent {
  color: black;
  display: none;
  
}

button.tablink:focus{
	outline:none;
}

.kb-map .MuiInputLabel-formControl{
	position:unset!important;
	padding-left:7px;
}

textarea#comment{
	height:31px;
}

.btn:focus{
	box-shadow:none;
	
}
.btn.delete{
	padding-top:18px;
	font-size: 19px!important;
}
.mt-1-4
{
	margin-top: 1.4rem!important;
}
.btn.add-user
{
	color: #333!important;
    background-color: #f4f4f4!important;
    border: 1px solid #f4f4f4!important;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,.15)!important;
}
.w-24{
	width:24%!important;
}
.w-52{
	width:52%!important;
}
a.show-more-link{
	color:#007bff!important;
	cursor:pointer;
}
.btn-primary:disabled{
	background-color:#cccccc!important;
}
.md-snackbar{
	top:50%!important;
	bottom:50%!important;
	background:#ffffff!important;
}
.md-snackbar-container{
	position:fixed!important;
	background: rgba(0,0,0,.4);
    bottom: 0;
    left: 0;
    opacity: 1;
   
    right: 0;
    top: 0;
   
    transition-property: opacity;
    z-index: 20;
}
.md-snackbar--toast{
	color:#000000;
}

a[data-toggle="tooltip"]{
	color:#cccccc!important;
}
a[data-toggle="tooltip"]:hover{
	color:#cccccc!important;
}

.kb-map .MuiInputLabel-shrink{
	visibility:hidden!important;
}
.kb-map .form-control[readonly] {
	background-color:#ffffff!important;
}
h3{
	white-space: unset!important;
}

button.show-more{
	color:rgb(3,169,244);
	font-weight:normal;
}

button.show-more:hover{
	background:none;
	font-weight:bold;
}
.h-0{
	height:0px!important;
	width: 0px!important;
}
.h-0 div,.h-0 button, .h-0 i,.h-0 span,.h-0 input ,.h-0  hr,.h-0 ul,.h-0 li,.h-0 label,.h-0 a,.h-0 h1,.h-0 h2,.h-0 h3,.h-0 h4,.h-0 h5,.h-0 h6{
	margin:0px!important;
	padding:0px!important;
	height:0px!important;
	width:0px!important;
	border:0px!important;
	font-size:0px!important;
	line-height:0px!important;
	display:none;
}

.bg-test{
	background-color:rgba(0,0,0,.03)!important;
}

div.microlight {
    font-size: 12px;
    margin: 0;
    padding: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    border-radius: 4px;
    background: #41444e;
    overflow-wrap: break-word;
    font-family: monospace;
    font-weight: 600;
    color: #fff;
}
.highlight-code>.microlight {
    overflow-y: auto;
    max-height: 400px;
    min-height: 6em;
}

textarea#feedback{
	height:50px;
}

.heading a.collapsed[aria-expanded="false"]:before{
  font-family: 'FontAwesome';
  content: "\f107";
  float: right;
  transition: all 0.5s;
}
.heading a[aria-expanded="true"]:before{
  font-family: 'FontAwesome';
  content: "\f106";
  float: right;
  transition: all 0.5s;
  
}

.sxSelectMain__3_9li{
	padding-left:7px;
	margin-bottom: 15px!important;
	margin-top: 15px!important;
}


.sxSelectHeader__Xlz5r{
	border: 0px solid #cccccc!important;
	    border-bottom: 1px solid #cccccc!important;
}

.sxSelectListsWrapper__2321j{
	width:30%!important;
}

.sxSubDropdownList__3hJLD {
    padding: 0;
    list-style: none;
    text-align: left;
    border: solid 1px #cccccc;
    border-radius: 4px;
    border-left: 0;
    background-color: #ffffff;
	height:-webkit-fit-content!important;
	height:-moz-fit-content!important;
	height:fit-content!important;
}
.sxDropdownList__31dMm  li.sxListItem__2kr9F{
	padding:0px!important;
}

/*ul.sxDropdownList__31dMm li:nth-child(6),.disable-select{
	opacity:0.5;
	pointer-events:none;
}*/

/*.faq ul.sxDropdownList__31dMm li:nth-child(1),.faq ul.sxDropdownList__31dMm li:nth-child(2),.bulk-faq ul.sxDropdownList__31dMm li:nth-child(1){
	opacity:0.5;
	pointer-events:none;
}*/

select.custom-select option:hover{
	background-color:#fff!important;
	border:none!important;
}

li.rc-menu-item{
	cursor:pointer;
}
.MuiRating-icon svg
{
	width: 1em!important;
    height: 1em!important;
}

input[type=text]#add:focus{
  outline: unset!important;
}

button.bg-white span.md-chip-text--hover{
  color:#000000!important;
}

.table.models td,.table.models th{
  vertical-align: middle!important;
}
.md-btn--icon.app-icon{
  padding:0px 0px 37px 10px!important;
  margin-right: 10px!important;
}
.md-btn--icon.md-btn--hover.app-icon{
  padding:0px 0px 37px 10px!important;
  margin-right: 10px!important;
}

#cursor {
  border-left: .1em solid black;
  -webkit-animation: blink .7s steps(1) infinite;
          animation: blink .7s steps(1) infinite;
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
 
@media only screen and (max-width: 400px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 60px!important;
    }
}
@media only screen and (max-width: 550px) and (min-width: 400px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 100px!important;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 550px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 115px!important;
    }
}
@media only screen and (max-width: 1200px) and (min-width: 1000px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 125px!important;
    }
}
@media only screen and (max-width: 1500px) and (min-width: 1200px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 145px!important;
    }
}
@media only screen and (max-width: 1700px) and (min-width: 1500px)
{
	.kb-map .MuiSelect-select.MuiSelect-select{
		padding-right: 0px!important;
	}
	.select-kb {
    min-width: 165px!important;
    }
}

.zoom-in-out-box {
 
  width: 50px;
  height: 70px;
  position: fixed;
  left:50%;
  top:50%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: zoom-in-zoom-out 1s ease infinite;
          animation: zoom-in-zoom-out 1s ease infinite;
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
  display: none;
}
.rcw-conversation-container .rcw-header {
	background-color: #ffffff;
	border-radius: 10px 10px 0 0;
	color: #000;
	display: flex;
	/* -ms-flex-direction: column;
	flex-direction: column;
	text-align: center; */
    /* padding: 15px 0 25px */
	padding: 0;
	border-bottom: 1px solid #ccc;
}
 .widget-line
{
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 25px;
}
.rcw-plain-message p a:not([href]):not([tabindex]){
	text-decoration: underline;
	cursor: pointer;
	padding: 5px 0px 5px 0px;
	display: block;
}
.curiosum-logo{			
		position: absolute;		
		width: 5rem;
		height: 1rem;		
		top: 4px;
		z-index: 99;
		background-size: 100px;
		background-color: #fff;	
		font-weight: bold;
		color: #000;
		font-size: 12px;
}
.curiosum-user{
	position: absolute;		
		width: 2.5rem;
		height: 1rem;		
		top: 4px;
		right:0px;
		z-index: 99;
		background-size: 100px;
		background-color: #fff;	
		font-weight: bold;
		color: #000;
		font-size: 12px;
		text-align: right;
		padding-right: 2px;
}
.widget-line:after {
    width: 100%;
    content: "";
    border-bottom: .5px solid #eee;
    position: absolute;
    padding-top: 15px;
    right: 0;
}

.rcw-conversation-container .rcw-title {
	font-size: 16px;
	margin: 0;
	padding: 8px;
	width: 55%;
	text-align: left;
}
.rcw-title.resize {
	width:88%;
}

.rcw-conversation-container .rcw-close-button {
	display: none
}
.rcw-widget-container-resize
{
	top: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
	z-index: 1;
	width: 98vw;
	left:12px;
	height: 90vh;
}

.rcw-conversation-container .avatar {
	width: 25px;
	height: 25px;
	
	margin-right: 5px;
	vertical-align: middle
}
.rcw-header-icons {
    display: flex;
	align-items: center;
	float: right;
	width:45%;
	justify-content: flex-end;
	padding-right: 5px;
}
.rcw-setting {
    background: transparent;
    color: black;
    padding: 1px !important;
    height: 25px;
	width: 25px;
	border: none;
	outline: 0 none;
}
.rcw-setting.disabled {
    color: #666;
}
.rcw-setting span {
	margin-right: 2px;
	font-weight: bold;
}
.rcw-setting.small-font {
	font-size: small;
}
.rcw-setting.medium-font {
	font-size: medium;
}
.rcw-setting.large-font {
	font-size: large;
}
.rcw-full-screen .rcw-header {
	border-radius: 0;
	flex-shrink: 0;
	position: relative
}

.rcw-full-screen .rcw-title {
	padding: 0 0 15px
}

.rcw-full-screen .rcw-close-button {
	background-color: #35cce6;
	border: 0;
	display: block;
	position: absolute;
	right: 10px;
	top: 20px;
	width: 40px
}
.logo {
	background-size: cover;
	width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
	margin: 0 2px;
	background-position: 0 -5px;
	background-repeat: no-repeat;
}

.rcw-full-screen .rcw-close {
	width: 20px;
	height: 20px
}
.rcw-welcome-message {
	padding: 10px 0;
	border-bottom: 1px solid #ccc;
	width: 100%;
}
.rcw-plain-message, div#passage, a#toggle {
	
	color: #000;
	font-size: 12px;
	padding: 0px;
	width: 96%;
	margin: 0px 0;
	display: block;
	line-height:20px;
	
    
}
a#toggle{
	color: rgb(3,169,244);
}

.buttons_info {
	background: #f4f7f9;
    color: #333;
    font-size: 16px;
    width: 46%;
    margin: 10px 10px 10px 0;
    padding: 12px;
    line-height: 20px;
    border-radius: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 0;
    border-left: 0;
	border-right: 0;
	cursor: pointer;
	
}
.buttons_info:hover {
	background-color: #a3eaf7;
	margin-left: auto;
	border-bottom: 1px solid #35cce6;

}
@media screen and (max-width:800px) {
	.rcw-conversation-container .rcw-header {
		border-radius: 0;
		flex-shrink: 0;
		position: relative
	}
	.rcw-conversation-container .rcw-title {
		padding: 0 0 15px
	}
	.rcw-conversation-container .rcw-close-button {
		background-color: #35cce6;
		border: 0;
		display: block;
		position: absolute;
		right: 10px;
		top: 20px;
		width: 40px
	}
	.rcw-conversation-container .rcw-close {
		width: 20px;
		height: 20px
	}
}
.rcw-messages-container.small{
	font-size: small !important;
}
.rcw-messages-container.large {
	font-size: large;
}
.rcw-messages-container {
	background-color: #fff;
	
	max-height: 410px;
	overflow: auto;
	font-size: medium;
	text-align: left;
	padding:10px 20px;
	min-height: 250px;
}
.rcw-messages-container.resize {
	height: 90vh;
	max-height: 510px;
}

.rcw-full-screen .rcw-messages-container {
	height: 100%;
	max-height: none
}

@media screen and (max-width:800px) {
	.rcw-messages-container {
		height: 100%;
		max-height: none
	}
}

.rcw-sender {
	align-items: center;
	display: flex;
	background-color: #f4f7f9;
	height: 45px;
	padding: 5px;
	border-radius: 0 0 10px 10px
}

.rcw-new-message {
	width: 100%;
	border: 0;
	background-color: #f4f7f9;
	height: 30px;
	padding-left: 15px;
	font-size: 14px;
}

.rcw-new-message:focus {
	outline: none
}

.rcw-send {
	background: #f4f7f9;
	border: 0
}

.rcw-send .rcw-send-icon {
	height: 25px
}

@media screen and (max-width:800px) {
	.rcw-sender {
		border-radius: 0;
		flex-shrink: 0
	}
}

.rcw-conversation-container {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: c;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: c;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: c;
	animation-fill-mode: forwards;
	border-radius: 10px;
	box-shadow: 0 2px 10px 1px #b5b5b5
}

.rcw-slide-out {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: d;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: d;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: d;
	animation-fill-mode: forwards
}

.rcw-full-screen .rcw-conversation-container {
	display: flex;
	flex-direction: column;
	height: 100%
}

@media screen and (max-width:800px) {
	.rcw-conversation-container {
		display: flex;
		flex-direction: column;
		height: 100%
	}
}

.rcw-launcher .rcw-badge {
	position: fixed;
	top: -10px;
	right: -5px;
	background-color: red;
	color: #fff;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%
}

.rcw-launcher {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: c;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: c;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: c;
	animation-fill-mode: forwards;
	align-self: flex-end;
	background-color: #35cce6;
	border: 0;
	border-radius: 50%;
	box-shadow: 0 2px 10px 1px #b5b5b5;
	height: 60px;
	margin-top: 10px;
	width: 60px;
	font-size:large;
	color: white;
}

.rcw-launcher:focus {
	outline: none
}

.rcw-open-launcher {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: b;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: b;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: b;
	animation-fill-mode: forwards
}

.rcw-close-launcher {
	width: 20px;
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: a;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: a;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: a;
	animation-fill-mode: forwards
}

@media screen and (max-width:800px) {
	.rcw-launcher {
		bottom: 0;
		margin: 20px;
		position: fixed;
		right: 0
	}
	.rcw-hide-sm {
		display: none
	}
}

@-webkit-keyframes a {
	0% {
		-webkit-transform: rotate(-90deg);
		        transform: rotate(-90deg)
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0)
	}
}

@keyframes a {
	0% {
		-webkit-transform: rotate(-90deg);
		        transform: rotate(-90deg)
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0)
	}
}

@-webkit-keyframes b {
	0% {
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg)
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0)
	}
}

@keyframes b {
	0% {
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg)
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0)
	}
}

@-webkit-keyframes c {
	0% {
		opacity: 0;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}

@keyframes c {
	0% {
		opacity: 0;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}

@-webkit-keyframes d {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
	to {
		opacity: 0;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px)
	}
}

@keyframes d {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
	to {
		opacity: 0;
		-webkit-transform: translateY(10px);
		        transform: translateY(10px)
	}
}

.rcw-widget-container {
	bottom: 0;
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px 0;
	max-width: 480px;
	position: fixed;
	right: 0;
	z-index: 111
}

.rcw-opened {
	width: 90vw
}

.rcw-full-screen {
	margin: 0;
	max-width: none;
	width: 100%
}

.rcw-full-screen.rcw-opened {
	width: 100%;
	height: 100%
}

@media screen and (max-width:800px) {
	.rcw-widget-container {
		margin: 0;
		max-width: none;
		width: 100%
	}
	.rcw-widget-container.rcw-opened {
		height: 100%
	}
}

.rcw-message {
	margin: 10px;
	display: flex;
}

.rcw-client {
	text-align: right;
	margin-left: auto;
	
}

.rcw-client,
.rcw-response,div#passage, a#toggle {
	border-radius: 10px;
	padding: 15px;
	max-width: 250px;
	
}
.rcw-response,div#passage, a#toggle {
	max-width: 415px;
	padding: 10px;
	text-align: left;
}


.rcw-project-buttons {
	max-width: 290px;
	margin-right: 0px;
	padding-right: 0px;
}
.rcw-project-btn{
	border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    margin: 0 10px;
	color: #333;
	cursor: pointer;
	font-weight: 700;
}
.rcw-project-btn:hover{
	background:#666;
	color: #fff;
	
}
input.rcw-project-btn:last-child {
    margin-right: 0px;
}
.rcw-message {
	font-size: 14px
}

.rcw-message-text p {
	margin: 0
}

.rcw-avatar {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px
}

.rcw-snippet {
	background-color: #f4f7f9;
	border-radius: 10px;
	padding: 15px;
	max-width: 215px;
	text-align: left
}

.rcw-snippet-title {
	margin: 0
}

.rcw-snippet-details {
	border-left: 2px solid #35e65d;
	margin-top: 5px;
	padding-left: 10px
}

.rcw-link {
	font-size: 12px
}

.rc-menu-submenu-popup{
	z-index: 666 !important;
}
.bg-primary{
    background-color: #bce8f1!important;
}
.ansfont{
    font-family: arial,sans-serif;
    font-size: 16px;
    overflow-wrap: break-word;
}

.card-title{
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    
    color: #5f6368;
}
ul {
    list-style: none;
  }
  
  /*#searchdetail ul li::before {
    content: "\2022";
    color: deepskyblue;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }*/
  a:not([href]):not([tabindex]).pdflink{
    color: #1a0dab;
    cursor: pointer;
  }
  a:not([href]):not([tabindex]).pdflink:hover{
    text-decoration: underline;
  }
  a.pdflink,a.pdflink:hover{
    color: #006621;
  }
  
