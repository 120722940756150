.rcw-conversation-container .rcw-header {
	background-color: #ffffff;
	border-radius: 10px 10px 0 0;
	color: #000;
	display: -ms-flexbox;
	display: flex;
	/* -ms-flex-direction: column;
	flex-direction: column;
	text-align: center; */
    /* padding: 15px 0 25px */
	padding: 0;
	border-bottom: 1px solid #ccc;
}
 .widget-line
{
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 25px;
}
.rcw-plain-message p a:not([href]):not([tabindex]){
	text-decoration: underline;
	cursor: pointer;
	padding: 5px 0px 5px 0px;
	display: block;
}
.curiosum-logo{			
		position: absolute;		
		width: 5rem;
		height: 1rem;		
		top: 4px;
		z-index: 99;
		background-size: 100px;
		background-color: #fff;	
		font-weight: bold;
		color: #000;
		font-size: 12px;
}
.curiosum-user{
	position: absolute;		
		width: 2.5rem;
		height: 1rem;		
		top: 4px;
		right:0px;
		z-index: 99;
		background-size: 100px;
		background-color: #fff;	
		font-weight: bold;
		color: #000;
		font-size: 12px;
		text-align: right;
		padding-right: 2px;
}
.widget-line:after {
    width: 100%;
    content: "";
    border-bottom: .5px solid #eee;
    position: absolute;
    padding-top: 15px;
    right: 0;
}

.rcw-conversation-container .rcw-title {
	font-size: 16px;
	margin: 0;
	padding: 8px;
	width: 55%;
	text-align: left;
}
.rcw-title.resize {
	width:88%;
}

.rcw-conversation-container .rcw-close-button {
	display: none
}
.rcw-widget-container-resize
{
	top: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
	z-index: 1;
	width: 98vw;
	left:12px;
	height: 90vh;
}

.rcw-conversation-container .avatar {
	width: 25px;
	height: 25px;
	
	margin-right: 5px;
	vertical-align: middle
}
.rcw-header-icons {
    display: flex;
	align-items: center;
	float: right;
	width:45%;
	justify-content: flex-end;
	padding-right: 5px;
}
.rcw-setting {
    background: transparent;
    color: black;
    padding: 1px !important;
    height: 25px;
	width: 25px;
	border: none;
	outline: 0 none;
}
.rcw-setting.disabled {
    color: #666;
}
.rcw-setting span {
	margin-right: 2px;
	font-weight: bold;
}
.rcw-setting.small-font {
	font-size: small;
}
.rcw-setting.medium-font {
	font-size: medium;
}
.rcw-setting.large-font {
	font-size: large;
}
.rcw-full-screen .rcw-header {
	border-radius: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	position: relative
}

.rcw-full-screen .rcw-title {
	padding: 0 0 15px
}

.rcw-full-screen .rcw-close-button {
	background-color: #35cce6;
	border: 0;
	display: block;
	position: absolute;
	right: 10px;
	top: 20px;
	width: 40px
}
.logo {
	background-size: cover;
	width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
	margin: 0 2px;
	background-position: 0 -5px;
	background-repeat: no-repeat;
}

.rcw-full-screen .rcw-close {
	width: 20px;
	height: 20px
}
.rcw-welcome-message {
	padding: 10px 0;
	border-bottom: 1px solid #ccc;
	width: 100%;
}
.rcw-plain-message, div#passage, a#toggle {
	
	color: #000;
	font-size: 12px;
	padding: 0px;
	width: 96%;
	margin: 0px 0;
	display: block;
	line-height:20px;
	
    
}
a#toggle{
	color: rgb(3,169,244);
}

.buttons_info {
	background: #f4f7f9;
    color: #333;
    font-size: 16px;
    width: 46%;
    margin: 10px 10px 10px 0;
    padding: 12px;
    line-height: 20px;
    border-radius: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 0;
    border-left: 0;
	border-right: 0;
	cursor: pointer;
	
}
.buttons_info:hover {
	background-color: #a3eaf7;
	margin-left: auto;
	border-bottom: 1px solid #35cce6;

}
@media screen and (max-width:800px) {
	.rcw-conversation-container .rcw-header {
		border-radius: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		position: relative
	}
	.rcw-conversation-container .rcw-title {
		padding: 0 0 15px
	}
	.rcw-conversation-container .rcw-close-button {
		background-color: #35cce6;
		border: 0;
		display: block;
		position: absolute;
		right: 10px;
		top: 20px;
		width: 40px
	}
	.rcw-conversation-container .rcw-close {
		width: 20px;
		height: 20px
	}
}
.rcw-messages-container.small{
	font-size: small !important;
}
.rcw-messages-container.large {
	font-size: large;
}
.rcw-messages-container {
	background-color: #fff;
	
	max-height: 410px;
	overflow: auto;
	font-size: medium;
	text-align: left;
	padding:10px 20px;
	min-height: 250px;
}
.rcw-messages-container.resize {
	height: 90vh;
	max-height: 510px;
}

.rcw-full-screen .rcw-messages-container {
	height: 100%;
	max-height: none
}

@media screen and (max-width:800px) {
	.rcw-messages-container {
		height: 100%;
		max-height: none
	}
}

.rcw-sender {
	-ms-flex-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;
	background-color: #f4f7f9;
	height: 45px;
	padding: 5px;
	border-radius: 0 0 10px 10px
}

.rcw-new-message {
	width: 100%;
	border: 0;
	background-color: #f4f7f9;
	height: 30px;
	padding-left: 15px;
	font-size: 14px;
}

.rcw-new-message:focus {
	outline: none
}

.rcw-send {
	background: #f4f7f9;
	border: 0
}

.rcw-send .rcw-send-icon {
	height: 25px
}

@media screen and (max-width:800px) {
	.rcw-sender {
		border-radius: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0
	}
}

.rcw-conversation-container {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: c;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: c;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: c;
	animation-fill-mode: forwards;
	border-radius: 10px;
	box-shadow: 0 2px 10px 1px #b5b5b5
}

.rcw-slide-out {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: d;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: d;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: d;
	animation-fill-mode: forwards
}

.rcw-full-screen .rcw-conversation-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%
}

@media screen and (max-width:800px) {
	.rcw-conversation-container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100%
	}
}

.rcw-launcher .rcw-badge {
	position: fixed;
	top: -10px;
	right: -5px;
	background-color: red;
	color: #fff;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%
}

.rcw-launcher {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: c;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: c;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: c;
	animation-fill-mode: forwards;
	-ms-flex-item-align: end;
	align-self: flex-end;
	background-color: #35cce6;
	border: 0;
	border-radius: 50%;
	box-shadow: 0 2px 10px 1px #b5b5b5;
	height: 60px;
	margin-top: 10px;
	width: 60px;
	font-size:large;
	color: white;
}

.rcw-launcher:focus {
	outline: none
}

.rcw-open-launcher {
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: b;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: b;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: b;
	animation-fill-mode: forwards
}

.rcw-close-launcher {
	width: 20px;
	-webkit-animation-delay: 0;
	-webkit-animation-duration: .5s;
	-webkit-animation-name: a;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-delay: 0;
	-moz-animation-duration: .5s;
	-moz-animation-name: a;
	-moz-animation-fill-mode: forwards;
	animation-delay: 0;
	animation-duration: .5s;
	animation-name: a;
	animation-fill-mode: forwards
}

@media screen and (max-width:800px) {
	.rcw-launcher {
		bottom: 0;
		margin: 20px;
		position: fixed;
		right: 0
	}
	.rcw-hide-sm {
		display: none
	}
}

@keyframes a {
	0% {
		transform: rotate(-90deg)
	}
	to {
		transform: rotate(0)
	}
}

@keyframes b {
	0% {
		transform: rotate(90deg)
	}
	to {
		transform: rotate(0)
	}
}

@keyframes c {
	0% {
		opacity: 0;
		transform: translateY(10px)
	}
	to {
		opacity: 1;
		transform: translateY(0)
	}
}

@keyframes d {
	0% {
		opacity: 1;
		transform: translateY(0)
	}
	to {
		opacity: 0;
		transform: translateY(10px)
	}
}

.rcw-widget-container {
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0 20px 20px 0;
	max-width: 480px;
	position: fixed;
	right: 0;
	z-index: 111
}

.rcw-opened {
	width: 90vw
}

.rcw-full-screen {
	margin: 0;
	max-width: none;
	width: 100%
}

.rcw-full-screen.rcw-opened {
	width: 100%;
	height: 100%
}

@media screen and (max-width:800px) {
	.rcw-widget-container {
		margin: 0;
		max-width: none;
		width: 100%
	}
	.rcw-widget-container.rcw-opened {
		height: 100%
	}
}

.rcw-message {
	margin: 10px;
	display: -ms-flexbox;
	display: flex;
}

.rcw-client {
	text-align: right;
	margin-left: auto;
	
}

.rcw-client,
.rcw-response,div#passage, a#toggle {
	border-radius: 10px;
	padding: 15px;
	max-width: 250px;
	
}
.rcw-response,div#passage, a#toggle {
	max-width: 415px;
	padding: 10px;
	text-align: left;
}


.rcw-project-buttons {
	max-width: 290px;
	margin-right: 0px;
	padding-right: 0px;
}
.rcw-project-btn{
	border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    margin: 0 10px;
	color: #333;
	cursor: pointer;
	font-weight: 700;
}
.rcw-project-btn:hover{
	background:#666;
	color: #fff;
	
}
input.rcw-project-btn:last-child {
    margin-right: 0px;
}
.rcw-message {
	font-size: 14px
}

.rcw-message-text p {
	margin: 0
}

.rcw-avatar {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px
}

.rcw-snippet {
	background-color: #f4f7f9;
	border-radius: 10px;
	padding: 15px;
	max-width: 215px;
	text-align: left
}

.rcw-snippet-title {
	margin: 0
}

.rcw-snippet-details {
	border-left: 2px solid #35e65d;
	margin-top: 5px;
	padding-left: 10px
}

.rcw-link {
	font-size: 12px
}

.rc-menu-submenu-popup{
	z-index: 666 !important;
}